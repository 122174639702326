import type { SwapListingOffer } from '@backmarket/http-api/src/api-specs-buyback/customer/getEstimationSwapPrice'

export interface Estimation {
  id: number
  offerPriceRaw: number
  offerPrice: string
  price: string
  hasOffer: boolean
  model: string | null
  label: string
}

export const getEstimationSwapPriceAdapter = (
  estimations: Array<SwapListingOffer>,
): Estimation[] => {
  return estimations.map((estimation) => ({
    price: estimation.price_with_swap_discount_with_currency,
    offerPriceRaw: estimation.swap_discount,
    id: estimation.listing_id,
    model: estimation.model,
    hasOffer: estimation.cart_has_swap_offer,
    label: estimation.cart_has_swap_offer
      ? 'swap_product_page_banner_offer_in_cart'
      : 'swap_product_page_banner_offer',
    offerPrice: estimation.swap_discount_with_currency,
  }))
}
