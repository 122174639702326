import { type LocationQuery } from 'vue-router'

import { getEstimationSwapPrice } from '@backmarket/http-api/src/api-specs-buyback/customer/getEstimationSwapPrice'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { defineStore } from 'pinia'

import {
  type Estimation,
  getEstimationSwapPriceAdapter,
} from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'

export enum EndpointStatus {
  IDLE,
  PENDING,
  RESOLVED,
  REJECTED,
}

export interface Swap {
  estimations: Array<Estimation>
  enabled: boolean
  status: EndpointStatus
  initialPayload?: LocationQuery
}

export const useSwapStore = defineStore('swap', {
  state: (): Swap => ({
    estimations: [],
    enabled: true,
    status: EndpointStatus.IDLE,
    initialPayload: undefined,
  }),
  actions: {
    declineEstimation() {
      this.enabled = false
    },
    resetEstimations() {
      this.estimations = []
    },
    async fetchSwapEstimations(
      listings: Array<number>,
      mobilePlanOfferId?: string | null,
    ) {
      this.status = EndpointStatus.PENDING
      try {
        const response = await $httpFetch(getEstimationSwapPrice, {
          queryParams: {
            listing_id: listings,
            mobile_plan_offer_id: mobilePlanOfferId || undefined,
          },
        })

        if (response) {
          this.estimations = getEstimationSwapPriceAdapter(response)
        }

        this.status = EndpointStatus.RESOLVED
      } catch (error) {
        this.status = EndpointStatus.REJECTED
      }
    },
    setInitialPayload(newInitialPayload: LocationQuery) {
      this.initialPayload = newInitialPayload
    },
    resetInitialPayload() {
      this.initialPayload = undefined
    },
  },
})
