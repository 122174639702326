<template>
  <slot></slot>
</template>

<script setup lang="ts">
import { onErrorCaptured } from 'vue'

export interface ErrorPayload {
  componentName: string
  info: string
  error: Error
}

const emit = defineEmits<{
  (event: 'error-caught', payload: ErrorPayload): void
}>()

onErrorCaptured((error, instance, info) => {
  // eslint-disable-next-line no-underscore-dangle
  const componentName = instance?.$options?.__name || 'none'

  emit('error-caught', {
    componentName,
    info,
    error,
  })

  return false
})
</script>
